import React, { useState, useEffect } from "react";
import { useCurrentUser } from "../../../account/context/user.context";
import Dialog from "../../../shared/components/Dialog/Dialog";
import { LoggedUser } from "../../../../models/User";
import UserDataWizardStorage from "./UserDataWizardStorage";
import UserDataWizardForm, { UserDataWizardFormValues } from "./form";
import { userDataWizardFormSchema } from "./schema";
import { UserService } from "../../../../services/UserService";
import { MessageService } from "../../../../services/MessageService";

interface UserDataWizardProps {}

const OPEN_INTERVAL_IN_DAYS = 7;

const shouldOpen = (user: LoggedUser) => {
  const userDataFilled = !!(user.firstName && user.lastName);

  if (userDataFilled) return false;

  const lastOpenDate = UserDataWizardStorage.getLastOpen();
  if (!lastOpenDate) return true;

  const now = new Date();
  const diff = now.getTime() - lastOpenDate.date.getTime();
  const days = diff / (1000 * 60 * 60 * 24);

  return days >= OPEN_INTERVAL_IN_DAYS;
};

const getInitialValues = (user: LoggedUser): UserDataWizardFormValues => ({
  firstName: user.firstName,
  lastName: user.lastName,
  phone: user.phone,
  jobTitle: user.jobTitle,
});

/**
 * User data wizard component
 * If user didn't fill in his data, this component will be shown to him
 * to fill in his data in periods of time described as OPEN_INTERVAL_IN_DAYS
 */
const UserDataWizard = ({}: UserDataWizardProps) => {
  const [open, setOpen] = useState(false);
  const { user } = useCurrentUser();

  const [formValues, setFormValues] = useState<UserDataWizardFormValues>(
    getInitialValues(user)
  );
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    if (!user) return;

    const shouldOpenWizard = shouldOpen(user);
    if (shouldOpenWizard) {
      setOpen(true);
      UserDataWizardStorage.saveLastOpen(new Date());
    }
  }, []);

  const handleChange = (values: UserDataWizardFormValues) => {
    setFormValues(values);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateUserData = async () => {
    try {
      await UserService.updateLoggedUser({
        phone: formValues.phone,
        jobTitle: formValues.jobTitle,
        firstName: formValues.firstName,
        lastName: formValues.lastName,
      });
      MessageService.success("User data updated successfully");
      handleClose();
    } catch (error) {
      MessageService.error("Failed to update user data");
    }
  };

  const handleConfirm = async () => {
    setErrorMessage("");
    const validationResults = userDataWizardFormSchema.validate(formValues);

    if (validationResults.error) {
      setErrorMessage(validationResults.error.message);
      return;
    }

    updateUserData();
  };

  return (
    <Dialog
      size="medium"
      open={open}
      onClose={handleClose}
      confirmButtonProps={{
        onClick: handleConfirm,
      }}
      cancelButtonProps={{
        onClick: handleClose,
      }}
      title="We want to know more about you"
      description="Please fill in the following fields to complete your profile"
    >
      <UserDataWizardForm values={formValues} onChange={handleChange} />
      {!!errorMessage && (
        <p className="text-red-500 text-sm mt-4">{errorMessage}</p>
      )}
    </Dialog>
  );
};

export default UserDataWizard;
