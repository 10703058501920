import React from "react";
import InputGroup from "../../../shared/form/InputGroup";
import { ITranslationFields } from "../../../../locale/dictionary";

export interface UserDataWizardFormValues {
  firstName: string;
  lastName: string;
  phone: string;
  jobTitle: string;
}
export interface UserDataWizardFormProps {
  values: UserDataWizardFormValues;
  onChange: (values: UserDataWizardFormValues) => void;
}

const inputs = [
  {
    name: "firstName",
    label: "firstNameLabel",
    placeholder: "firstNamePlaceholder",
    required: true,
  },
  {
    name: "lastName",
    label: "lastnameLabel",
    placeholder: "lastnamePlaceholder",
    required: true,
  },
  { name: "phone", label: "phoneLabel", placeholder: "phonePlaceholder" },
  {
    name: "jobTitle",
    label: "jobTitleLabel",
    placeholder: "jobTitlePlaceholder",
  },
];

const UserDataWizardForm = ({ values, onChange }: UserDataWizardFormProps) => {
  const formValues = values;

  const handleChange = (fieldName: string) => (e: any) => {
    onChange({ ...values, [fieldName]: e.target.value });
  };

  return (
    <form>
      <ul className="grid grid-cols-2 gap-x-4 gap-y-6">
        {inputs.map((input) => (
          <li key={input.name}>
            <InputGroup
              required={input.required}
              bordered
              inputProps={{
                name: input.name,
                value: formValues[input.name],
                placeholder: input.placeholder as ITranslationFields,
                onChange: handleChange(input.name),
              }}
              label={input.label as ITranslationFields}
            />
          </li>
        ))}
      </ul>
    </form>
  );
};

export default UserDataWizardForm;
