import { EnvVariables } from "./";

const env: EnvVariables = {
  APP_DOMAIN: "skyebrowse.com",
  API_URL: "https://staging.api.skyebrowse.com/",
  API_V3_URL: "https://staging.api.skyebrowse.com/",
  MAP_URL: "https://staging.map.skyebrowse.com/",
  UPLOAD_BUCKET_URL: "skyebrowse-staging-uploads.s3.amazonaws.com",
  GOOGLE_MAPS_API_KEY: "AIzaSyAo-rOZ0gwSCQA1Dl3j_fFfYxbmxP8f7wc",
  GOOGLE_ANALYTIC_API_KEY: "non-empty-key",
  STRIPE_PRICING_TABLE_ID: "prctbl_1MM6RTFCf9LMLUSNRNaSJHVV",
  STRIPE_PUBLISHABLE_KEY: "pk_test_hLTYLk4SUWhXIKVJcUza0MyJ00PUHAZSwn",
  STRIPE_CUSTOMER_PORTAL_ID: "test_9AQdSe9CV8X4fmgeUU",
  FACEBOOK_CONVERSION_API_KEY: "non-empty-key",
  FACEBOOK_PIXEL_ID: "non-empty-key",
};

export default env;
